
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';

//window.$ = window.jQuery = require('jquery');
//require('@popperjs/core');
//require('bootstrap');

require('bootstrap-datepicker');
require('jquery-typeahead');
require('magnific-popup');
require('lodash');
require('tippy.js');
require('paginationjs');

import 'tooltipster';
// require('tooltipster');

// import Sortable from 'sortablejs';

// window.Sortable = require('sortablejs');
//require('sortablejs');
//import Sortable from 'sortablejs';
//import Sortable from 'sortablejs/modular/sortable.complete.esm.js';

import fontawesome from '@fortawesome/fontawesome-free';
import { faFacebook, faTwitter, faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';

window.Vue = require('vue').default;

// Create a new Vue instance
//new Vue({
  //el: '#app',
  // other options...
//});